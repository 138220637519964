import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import AddIcon from '@mui/icons-material/Add';
import DialogTitle from 'components/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog, { DialogBaseProps } from 'components/Dialog';
import DiscountFormFields, {
  Fields,
  schema,
} from 'components/DiscountFormFields';

import { useDialog } from 'components/Dialogs';
import { useCreateDiscount } from 'features/Discount';
import { useClub } from 'components/ClubProvider';
import DialogContent from 'components/DialogContent';

type Props = DialogBaseProps;

export type AddDiscountDialogProps = Props;

const initial: Fields = {
  code: '',
  validFrom: null,
  expiresAt: null,
  quantity: '',
  type: 'absolute',
  amount: '',
};

const AddDiscountDialog = (props: Props) => {
  const [, { close }] = useDialog('discountAdd');
  const create = useCreateDiscount(useClub().id);

  const handleSubmit = useCallback(
    ({ quantity, amount, expiresAt, type, ...values }: Fields) => {
      const form = {
        quantity: quantity ? Number(quantity) : null,
        amount: Number(amount),
        expiresAt: expiresAt!,
        type: type!,
        ...values,
      };

      create.mutate(form, {
        onSuccess: () => {
          close();
        },
      });
    },
    [close, create]
  );

  return (
    <Dialog {...props} dialog="discountAdd">
      <Formik<Fields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Naujas nuolaidos kodas</DialogTitle>

          <DialogContent>
            <DiscountFormFields />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={create.isPending}
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}>
              Pridėti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddDiscountDialog;
