import { SyntheticEvent, useCallback, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import TableCell from '@mui/material/TableCell';
import Tabs from '@mui/material/Tabs';
import AbsencesTableRow from 'components/AbsencesTableRow';
import SelectableTableHead from 'components/SelectableTableHead';
import Table, { TableProps } from 'components/Table';

import {
  useAbsences,
  useApproveSessionAbsences,
  useCountsByStatus,
} from 'features/SessionAbsence';
import { SessionAbsenceStatus } from 'schema';
import { useSelectable } from 'components/MultiSelect';

const CURRENT_STATUS_FILTER_KEY = 'session_absence_list:status';

const AbsencesTable = (props: TableProps) => {
  const { hasSelected, selected } = useSelectable();
  const approve = useApproveSessionAbsences();

  const [status, setStatus] = useState<SessionAbsenceStatus>(
    (localStorage.getItem(CURRENT_STATUS_FILTER_KEY) as SessionAbsenceStatus) ??
      'submitted'
  );

  const params = { status };
  const absences = useAbsences(params);
  const counts = useCountsByStatus();

  const handleTabChange = useCallback(
    (_: SyntheticEvent, value: SessionAbsenceStatus) => {
      setStatus(value);
    },
    []
  );

  const handleApproveAllClick = useCallback(() => {
    approve.mutate({
      approve: selected().map(id => ({ id })),
    });
  }, [approve, selected]);

  const selectable = status === 'submitted';

  return (
    <Table
      paginated
      bulkSelect={selectable}
      count={absences.data?.meta?.pageInfo?.count}
      loading={absences.isPending || approve.isPending}
      actions={
        selectable && (
          <Button
            size="small"
            variant="outlined"
            disabled={!hasSelected}
            startIcon={<CheckIcon fontSize="small" />}
            onClick={handleApproveAllClick}>
            Tvirtinti
          </Button>
        )
      }
      head={
        <SelectableTableHead disabled={!selectable}>
          <TableCell component="th">ID</TableCell>
          <TableCell component="th">Data</TableCell>

          <TableCell component="th" align="center">
            Laikotarpis
          </TableCell>

          <TableCell component="th">Dalyvis</TableCell>
          <TableCell component="th">Globėjas</TableCell>
          <TableCell component="th">Globėjo tel.</TableCell>
          <TableCell component="th">Pastabos</TableCell>

          <TableCell component="th" align="center">
            Suma
          </TableCell>

          <TableCell component="th" align="center">
            Patvirtinta
          </TableCell>

          <TableCell component="th" />
        </SelectableTableHead>
      }
      tabs={
        <Tabs value={status} onChange={handleTabChange}>
          <Tab
            value="submitted"
            label={`Nauji [${counts.data?.submitted ?? 0}]`}
          />

          <Tab
            value="approved"
            label={`Patvirtinti [${counts.data?.approved ?? 0}]`}
          />

          <Tab
            value="refunded"
            label={`Gražinti [${counts.data?.refunded ?? 0}]`}
          />

          <Tab
            value="rejected"
            label={`Atmesti [${counts.data?.rejected ?? 0}]`}
          />
        </Tabs>
      }
      {...props}>
      {absences.data?.data.map(absence => (
        <AbsencesTableRow
          params={params}
          disabled={!selectable}
          id={absence.id}
          key={absence.id}
          absence={absence}
        />
      )) ?? []}
    </Table>
  );
};

export default AbsencesTable;
