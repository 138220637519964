import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import PageSkeleton from 'components/PageSkeleton';
import Page from 'components/Page';
import InvoiceDetails from 'components/InvoiceDetails';
import InvoicePayments from 'components/InvoicePayments';
import InvoiceUser from 'components/InvoiceUser';

import { useInvoice } from 'features/Invoice';

const InvoiceEdit = () => {
  const { id } = useParams();
  const invoiceId = Number(id);
  const invoiceQuery = useInvoice(invoiceId);

  if (!invoiceQuery.data) return <PageSkeleton>Kraunasi...</PageSkeleton>;

  return (
    <Page title={invoiceQuery.data.invoiceNumber}>
      <Grid container spacing={2}>
        <Grid xs={8}>
          <InvoiceDetails invoice={invoiceQuery.data} sx={{ mb: 2 }} />
          <InvoicePayments invoice={invoiceQuery.data} />
        </Grid>

        <Grid xs={4}>
          <InvoiceUser user={invoiceQuery.data.user} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default InvoiceEdit;
