import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { number, object, string } from 'yup';

import AddIcon from '@mui/icons-material/Add';
import DialogTitle from 'components/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog, { DialogBaseProps } from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import Stack from 'components/Stack';
import FormTextField from 'components/FormTextField';
import FormEuroTextField from 'components/FormEuroTextField';

import { useUpdateInvoiceItem } from 'features/Invoice';
import { useDialog } from 'components/Dialogs';
import { ClubInvoiceItemView, ClubInvoiceView } from 'schema';

type Fields = {
  name: string;
  amount: string;
};

type Props = DialogBaseProps & {
  invoice: ClubInvoiceView;
  item: ClubInvoiceItemView;
};

export type UpdateInvoiceItemDialogProps = Props;

const dialog = 'updateInvoiceItem';

export const schema = object({
  name: string().required('Prašome įvesti pavadinimą'),

  amount: number()
    .typeError('Prašome vesti skaičių')
    .required('Prašome įvesti sumą'),
});

const UpdateInvoiceItemDialog = ({ invoice, item, ...props }: Props) => {
  const [, { close }] = useDialog(dialog);
  const update = useUpdateInvoiceItem(invoice.id, item.id);

  const initial = {
    name: item.name,
    amount: item.price.toString(),
  };

  const handleSubmit = useCallback(
    ({ amount, ...rest }: Fields) => {
      const form = {
        ...rest,
        amount: Number(amount),
      };

      update.mutate(form, {
        onSuccess: close,
      });
    },
    [close, update]
  );

  return (
    <Dialog {...props} dialog={dialog}>
      <Formik<Fields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Nauja nuolaida</DialogTitle>

          <DialogContent>
            <Stack>
              <FormTextField name="name" label="Pavadinimas" />
              <FormEuroTextField name="amount" label="Suma" />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={update.isPending}
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}>
              Pridėti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default UpdateInvoiceItemDialog;
