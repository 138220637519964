import { useCallback } from 'react';
import { Formik } from 'formik';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import InvoiceTable from 'components/InvoiceTable';
import InvoiceCorrectionForm, {
  FormFields,
} from 'components/InvoiceCorrectionForm';
import Section, { SectionProps } from 'components/Section';
import InvoiceDownload from 'components/InvoiceDownload';
import InvoiceSyncStatus from 'components/InvoiceSyncStatus';

import { useUpdateInvoice, useUpdateSendInvoice } from 'features/Invoice';
import { formatDateTime } from 'lib';
import { ClubInvoiceView } from 'schema';

type Props = SectionProps & {
  invoice: ClubInvoiceView;
};

const handleSubmit = () => {
  // nothing to do here realy but formik insits on providing onSubmit prop
};

const InvoiceDetails = ({ invoice, ...props }: Props) => {
  const { notes, sentAt, invoiceNumber, paymentLink, isLocked } = invoice;
  const updateInvoice = useUpdateInvoice(invoice.id);
  const updateAndSend = useUpdateSendInvoice(invoice.id);

  const initial = {
    notes: notes ?? '',
  };

  const handleSend = useCallback(
    (form: FormFields) => {
      updateAndSend.mutate(form);
    },
    [updateAndSend]
  );

  const handleSave = useCallback(
    (form: FormFields) => {
      updateInvoice.mutate(form);
    },
    [updateInvoice]
  );

  return (
    <Section title="Sąskaita" {...props}>
      <Grid container spacing={2} sx={{ mt: 3, mb: 2, alignItems: 'center' }}>
        <Grid xs={4}>Sąskaitos dokumentas:</Grid>
        <Grid xs={8}>
          {sentAt ? <InvoiceDownload invoice={invoice} /> : invoiceNumber}
        </Grid>

        <Grid xs={4}>Mokėjimo nuoroda:</Grid>
        <Grid xs={8}>
          <Link href={paymentLink} target="_blank">
            {paymentLink}
          </Link>
        </Grid>

        <Grid xs={4}>Sąskaita išsiųsta:</Grid>
        <Grid xs={8}>{sentAt ? formatDateTime(sentAt) : 'Ne'}</Grid>

        <Grid xs={4}>Buhalterijos sinchronizacija:</Grid>
        <Grid xs={8}>
          <InvoiceSyncStatus invoice={invoice} />
        </Grid>
      </Grid>

      <Box sx={{ mb: 4 }}>
        <InvoiceTable invoice={invoice} />
      </Box>

      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        Sąskaitos korekcijos
      </Typography>

      <Formik<FormFields> initialValues={initial} onSubmit={handleSubmit}>
        <InvoiceCorrectionForm
          readOnly={isLocked}
          sending={updateAndSend.isPending}
          saving={updateInvoice.isPending}
          onSave={handleSave}
          onSend={handleSend}
        />
      </Formik>
    </Section>
  );
};

export default InvoiceDetails;
