import Dialog from 'components/Dialog';

import EditPaymentForm from 'components/EditPaymentForm';
import DialogSkeleton from 'components/DialogSkeleton';

import { useInvoicePaymentDetails } from 'features/Invoice';
import { ClubInvoiceView, ClubInvoiceViewPayment } from 'schema';

type Props = {
  invoice: ClubInvoiceView;
  payment: ClubInvoiceViewPayment;
};

const dialogName = 'paymentEdit';

const EditPaymentDialog = ({ invoice, payment }: Props) => {
  const query = useInvoicePaymentDetails(invoice.id, payment.id);

  return (
    <Dialog dialog={dialogName}>
      {query.data ? (
        <EditPaymentForm invoice={invoice} payment={query.data} />
      ) : (
        <DialogSkeleton>Kraunasi...</DialogSkeleton>
      )}
    </Dialog>
  );
};

export default EditPaymentDialog;
