import {
  ChangeEvent,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type Props = {
  children: ReactNode;
};

type SelectedItem = {
  id: number;
  selected: boolean;
};

type Ids = () => Promise<{ id: number }[]>;

type MultiSelectContext = {
  state: SelectableState;
  hasSelected: boolean;
  showSelectAllPrompt: boolean;
  selectedQuantity: () => number;
  selected: () => number[];
  isSelected: (id: number) => boolean;
  register: (id: number) => void;
  unregister: (id: number) => void;
  toggleAll: () => void;
  toggle: (id: number) => void;
  selectAll: (ids: Ids) => void;
  clearAll: () => void;
  setTotalCount: (id: number) => void;
};

type SelectableState = 'all' | 'some' | 'none';
type UseSelectableRow = [
  boolean,
  (event: ChangeEvent<HTMLInputElement>) => void,
];

const Context = createContext<MultiSelectContext>({
  state: 'none',
  hasSelected: false,
  showSelectAllPrompt: false,
  selectedQuantity: () => 0,
  selected: () => [],
  isSelected: () => false,
  register: () => {},
  unregister: () => {},
  toggleAll: () => {},
  toggle: () => {},
  selectAll: () => {},
  clearAll: () => {},
  setTotalCount: () => {},
});

export const useSelectableRow = (id: number): UseSelectableRow => {
  const { isSelected, toggle, register, unregister } = useContext(Context);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();

      toggle(id);
    },
    [id, toggle]
  );

  useEffect(() => {
    register(id);

    return () => unregister(id);
  }, [id, register, unregister]);

  return [isSelected(id), handleChange];
};

export const useSelectable = () => useContext(Context);

const MultiSelect = ({ children }: Props) => {
  const [items, setItems] = useState<SelectedItem[]>([]);
  const [invisible, setInvisible] = useState<number[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const state = useMemo(() => {
    const numberOfSelectedItems = items.reduce(
      (total, item) => (item.selected ? ++total : total),
      0
    );

    return numberOfSelectedItems > 0
      ? numberOfSelectedItems === items.length
        ? 'all'
        : 'some'
      : 'none';
  }, [items]);

  const hasSelected = state !== 'none';

  const selected = useCallback(
    () =>
      items
        .filter(({ selected }) => selected)
        .map(({ id }) => id)
        .concat(invisible),
    [items, invisible]
  );

  const selectedQuantity = useCallback(() => selected().length, [selected]);

  const isSelected = useCallback(
    (id: number) => items.find(item => item.id === id)?.selected ?? false,
    [items]
  );

  const toggleAll = useCallback(() => {
    setItems(current => {
      const selected = !hasSelected;

      if (!selected) {
        setInvisible([]);
      }

      return current.map(item => ({ ...item, selected }));
    });
  }, [hasSelected]);

  const toggle = useCallback((id: number) => {
    setItems(current =>
      current.map(item =>
        item.id === id ? { ...item, selected: !item.selected } : item
      )
    );
  }, []);

  const selectAll = useCallback(
    (ids: Ids) => {
      ids()
        .then(data => {
          let newItems = items;
          const invisible: number[] = [];

          data.forEach(({ id }) => {
            let selected = false;

            newItems = newItems.map(item => {
              if (item.id === id) {
                selected = true;

                return { ...item, selected: true };
              }

              return item;
            });

            if (!selected) {
              invisible.push(id);
            }
          });

          setItems(newItems);
          setInvisible(invisible);
        })
        .catch();
    },
    [items]
  );

  const clearAll = useCallback(() => {
    setItems(current => current.map(item => ({ ...item, selected: false })));
    setInvisible([]);
  }, []);

  const register = useCallback((id: number) => {
    setItems(current => {
      if (current.find(item => item.id === id)) return current;

      return [...current, { id, selected: false }];
    });
  }, []);

  const unregister = useCallback((id: number) => {
    setItems(current => current.filter(item => item.id !== id));
  }, []);

  return (
    <Context.Provider
      value={{
        state,
        hasSelected,
        showSelectAllPrompt: hasSelected && selectedQuantity() < totalCount,
        selectedQuantity,
        selected,
        isSelected,
        toggleAll,
        toggle,
        selectAll,
        clearAll,
        register,
        unregister,
        setTotalCount,
      }}>
      {children}
    </Context.Provider>
  );
};

export default MultiSelect;
