import { useCallback } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EuroPrice from 'components/EuroPrice';
import InvoiceItemRow from 'components/InvoiceItemRow';

import { useDialog } from 'components/Dialogs';
import { ClubInvoiceView } from 'schema';

type Props = {
  invoice: ClubInvoiceView;
};

const InvoiceTable = ({ invoice }: Props) => {
  const { items, total, isReadOnly } = invoice;
  const [, { open }] = useDialog('addInvoiceDiscount');

  const handleAddDiscountClick = useCallback(() => {
    open({ invoice });
  }, [invoice, open]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th">Paslauga</TableCell>

            <TableCell component="th" align="center">
              Kiekis
            </TableCell>

            <TableCell component="th" align="center">
              Kaina
            </TableCell>

            <TableCell component="th" align="center">
              Suma
            </TableCell>

            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {items.map((item, i) => (
            <InvoiceItemRow key={i} invoice={invoice} item={item} />
          ))}

          <TableRow>
            <TableCell colSpan={2}>
              {!isReadOnly && (
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<AddIcon fontSize="small" />}
                  onClick={handleAddDiscountClick}>
                  Pridėti nuolaidą
                </Button>
              )}
            </TableCell>

            <TableCell align="right">Viso</TableCell>

            <TableCell align="right">
              <EuroPrice>{total}</EuroPrice>
            </TableCell>

            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
