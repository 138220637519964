import Button from '@mui/material/Button';
import DialogContent from 'components/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import Dialog from 'components/Dialog';
import InvoiceSyncDetails from 'components/InvoiceSyncDetails';
import InvoiceSyncDetailsSkeleton from 'components/InvoiceSyncDetailsSkeleton';

import { useDialog } from 'components/Dialogs';
import { useInvoice, useInvoiceSync } from 'features/Invoice';
import { HttpSyncStatus } from 'schema';

type Invoice = {
  id: number;
  invoiceNumber: string;
  syncStatus: HttpSyncStatus;
};

type Props = {
  invoice: Invoice;
};

export type InvoiceSyncDialogProps = Props;

const InvoiceSyncDialog = ({ invoice }: Props) => {
  const [, { close }] = useDialog('invoiceSync');
  const details = useInvoice(invoice.id);
  const sync = useInvoiceSync(invoice.id);

  return (
    <Dialog dialog="invoiceSync" maxWidth="md">
      <DialogTitle onClose={close}>
        Sąskaitos {invoice.invoiceNumber} CRM sinchronizacija
      </DialogTitle>

      <DialogContent>
        {!details.data || !sync.data ? (
          <InvoiceSyncDetailsSkeleton />
        ) : (
          <InvoiceSyncDetails invoice={details.data} sync={sync.data} />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={close} variant="contained">
          Uždaryti
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceSyncDialog;
