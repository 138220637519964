import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Dialog, { DialogBaseProps } from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import DialogTitle from 'components/DialogTitle';
import InvoiceDiscountForm, {
  Fields,
  schema,
} from 'components/InvoiceDiscountForm';

import { useSetInvoicesDiscount } from 'features/Invoice';
import { useDialog } from 'components/Dialogs';
import { useConfirmableForm } from 'components/ConfirmDialog';

type Props = DialogBaseProps & {
  ids: number[];
};

export type SetInvoicesDiscountDialogProps = Props;

const dialog = 'setInvoicesDiscount';

const initial = {
  name: '',
  amount: '',
};

const SetInvoicesDiscountDialog = ({ ids, ...props }: Props) => {
  const [, { close }] = useDialog(dialog);
  const setDiscount = useSetInvoicesDiscount();

  const handleSubmit = useCallback(
    ({ amount, ...form }: Fields) => {
      const vars = {
        ids,
        ...form,
        amount: Number(amount),
      };

      setDiscount.mutate(vars, {
        onSuccess: close,
      });
    },
    [ids, setDiscount, close]
  );

  const confirm = useConfirmableForm({
    onConfirm: handleSubmit,
    title: 'Patvirtinkite',
    text: 'Ar tikrai norite pridėti nuolaidas į visas pažymėtas sąskaitas?',
  });

  return (
    <Dialog {...props} dialog={dialog}>
      <Formik<Fields>
        initialValues={initial}
        onSubmit={confirm}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Nustatyti nuolaidą</DialogTitle>

          <DialogContent>
            <InvoiceDiscountForm />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={setDiscount.isPending}
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}>
              Saugoti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default SetInvoicesDiscountDialog;
