import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FullScreen from 'components/FullScreen';
import LoadingButton from '@mui/lab/LoadingButton';
import FullscreenAppLogo from 'components/FullscreenAppLogo';
import MemberInvitationInfo from 'components/MemberInvitationInfo';

import { MemberInvitationDetails, UserDetailsView } from 'schema';
import { useAcceptMemberInvitation } from 'features/MemberInvitation';
import { useClearAuth } from 'features/Auth';

type Props = {
  invitation: MemberInvitationDetails;
  me?: UserDetailsView;
};

const MemberInvitationAccept = ({ invitation, me }: Props) => {
  const accept = useAcceptMemberInvitation(invitation.token);
  const clear = useClearAuth();
  const navigate = useNavigate();

  const handleAccept = useCallback(() => {
    const form = { user: null };

    accept.mutate(form, {
      onSuccess: ({ user }) => {
        if (me && me.id === user.id) {
          navigate('/');
        } else {
          clear().then(() => {
            navigate('/');
          });
        }
      },
    });
  }, [accept, clear, me, navigate]);

  return (
    <FullScreen>
      <Box sx={{ width: '500px' }}>
        <FullscreenAppLogo />
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Kvietimas prisijungti prie klubo</Typography>

          <MemberInvitationInfo invitation={invitation} />

          <LoadingButton
            fullWidth
            loading={accept.isPending}
            variant="contained"
            onClick={handleAccept}>
            Priimti kvietimą
          </LoadingButton>
        </Paper>
      </Box>
    </FullScreen>
  );
};

export default MemberInvitationAccept;
