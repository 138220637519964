import Select, { SelectProps } from 'components/Select';

import { useActivitiesDropdown } from 'features/Activity';
import { ActivityDropdownOption } from 'schema';

type Props = Omit<SelectProps<ActivityDropdownOption>, 'options'>;

const ActivitySelect = (props: Props) => {
  const query = useActivitiesDropdown();

  return <Select {...props} options={query.data ?? []} />;
};

export default ActivitySelect;
