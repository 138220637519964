import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from 'components/Select';
import { useShifts } from 'features/Shift';
import { Entity, ShiftView } from 'schema';

type Props = Omit<SelectProps<ShiftView>, 'options'> & {
  activity: Entity;
};

export type ShiftSelectProps = Props;

const ShiftSelect = ({ activity, ...props }: Props) => {
  const shifts = useShifts(activity.id);

  return (
    <Select
      options={shifts.data ?? []}
      loading={shifts.isPending}
      {...props}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} selected={selected}>
          {option.name} [{option.numberOfSessions}]
        </MenuItem>
      )}
    />
  );
};

export default ShiftSelect;
