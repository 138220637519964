import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import TableCell from '@mui/material/TableCell';
import AttendantsTableRow from 'components/AttendantsTableRow';
import Table, { TableProps } from 'components/Table';
import TableRow from 'components/TableRow';
import TextField from '@mui/material/TextField';
import Section from 'components/Section';

import { useAttendants } from 'features/Attendant';
import { debounce } from 'lib';
import { usePagination } from 'components/Pagination';

type Props = TableProps;

const AttendantsTable = (props: Props) => {
  const [search, setSearch] = useState('');
  const [pageInfo] = usePagination();
  const attendants = useAttendants({ search, ...pageInfo });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  const debouncedResults = useMemo(
    () => debounce(handleChange, 300),
    [handleChange]
  );

  useEffect(() => () => {
    debouncedResults.cancel();
  });

  return (
    <>
      <Section sx={{ mb: 2 }} title="Narių paieška">
        <TextField
          variant="standard"
          fullWidth
          label="Paieška"
          onChange={debouncedResults}
        />
      </Section>

      <Table
        paginated
        count={attendants.data?.meta?.pageInfo?.count}
        head={
          <TableRow>
            <TableCell component="th">Vardas pavardė</TableCell>

            <TableCell component="th" align="center">
              Globėjas
            </TableCell>

            <TableCell component="th" align="center">
              Paskyra
            </TableCell>

            <TableCell component="th" align="right">
              Registruotas
            </TableCell>
          </TableRow>
        }
        {...props}>
        {attendants.data?.data.map(attendant => (
          <AttendantsTableRow key={attendant.id} attendant={attendant} />
        ))}
      </Table>
    </>
  );
};

export default AttendantsTable;
