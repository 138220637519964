import SelectId, { SelectIdProps } from 'components/SelectId';
import { useActivitiesDropdown } from 'features/Activity';
import { ActivityDropdownOption } from 'schema';

type Props = Omit<SelectIdProps<ActivityDropdownOption>, 'options'> & {};

const ActivityIdSelect = (props: Props) => {
  const query = useActivitiesDropdown();

  return <SelectId {...props} options={query.data ?? []} />;
};

export default ActivityIdSelect;
