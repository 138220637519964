import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Dialog from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import DialogTitle from 'components/DialogTitle';
import PaymentFormFields, {
  Fields,
  schema,
} from 'components/PaymentFormFields';

import { useAddInvoicePayment } from 'features/Invoice';
import { useDialog } from 'components/Dialogs';

type Invoice = {
  id: number;
  invoiceNumber: string;
};

type Props = {
  invoice: Invoice;
};

const initial = {
  amount: '',
  comment: '',
};

const dialogName = 'paymentAdd';

const AddPaymentDialog = ({ invoice }: Props) => {
  const [, { close }] = useDialog(dialogName);
  const create = useAddInvoicePayment(invoice.id);

  const handleSubmit = useCallback(
    ({ amount, ...rest }: Fields) => {
      const form = {
        ...rest,
        amount: Number(amount),
      };

      create.mutate(form, {
        onSuccess: close,
      });
    },
    [close, create]
  );

  return (
    <Dialog dialog={dialogName}>
      <Formik<Fields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Naujas mokėjimas</DialogTitle>

          <DialogContent>
            <Typography sx={{ mb: 2 }}>
              Sąskaita: {invoice.invoiceNumber}
            </Typography>

            <PaymentFormFields />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={create.isPending}
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}>
              Pridėti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddPaymentDialog;
