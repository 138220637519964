import {
  ComponentProps,
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';

import SessionEditDialog from 'components/SessionEditDialog';
import SessionSendMessageDialog from 'components/SessionSendMessageDialog';
import SessionInviteAttendantsDialog from 'components/SessionInviteAttendantsDialog';
import SessionRegistrationDialog from 'components/SessionAttendantDialog';
import AddSessionDialog from 'components/AddSessionDialog';
import AddCategoryDialog from 'components/AddCategoryDialog';
import AddActivityDialog from 'components/AddActivityDialog';
import InvoiceSyncDialog from 'components/InvoiceSyncDialog';
import HttpRequestDialog from 'components/HttpRequestDialog';
import ConfirmDialog from 'components/ConfirmDialog';
import AddPageDialog from 'components/AddPageDialog';
import PageEditDialog from 'components/PageEditDialog';
import ChangeSessionDialog from 'components/ChangeSessionDialog';
import InviteMemberDialog from 'components/InviteMemberDialog';
import AddShiftDialog from 'components/AddShiftDialog';
import UpdateShiftDialog from 'components/UpdateShiftDialog';
import AddDiscountDialog from 'components/AddDiscountDialog';
import AddQuantityDiscountDialog from 'components/AddQuantityDiscountDialog';
import EditQuantityDiscountDialog from 'components/EditQuantityDiscountDialog';
import SessionReservationDialog from 'components/SessionReservationDialog';
import SessionAbsenceDialog from 'components/SessionAbsenceDialog';
import AddInvoiceDiscountDialog from 'components/AddInvoiceDiscountDialog';
import UpdateInvoiceItemDialog from 'components/UpdateInvoiceItemDialog';
import SetInvoicesDiscountDialog from 'components/SetInvoicesDiscountDialog';
import AddPaymentDialog from 'components/AddPaymentDialog';
import EditPaymentDialog from 'components/EditPaymentDialog';

type ContextProps = {
  dialogs: State;
  setDialogs: Dispatch<SetStateAction<State>>;
};

type Props = {
  children: ReactNode;
};

type DialogState<ComponentProps> = {
  open: boolean;
  props?: ComponentProps;
};

type ComponentTypes<C extends Config> = {
  [K in keyof C]: DialogState<ComponentProps<C[K]>>;
};

type State = ComponentTypes<Config>;

type UseDialog<ComponentProps> = [
  boolean,
  {
    open: (props: ComponentProps) => void;
    close: () => void;
  },
];

type Config = typeof config;
export type DialogType = keyof Config;
type PropType<K extends DialogType, S extends State> = S[K]['props'];

function initState(dialogs: Config): State {
  const result: any = {};

  Object.keys(dialogs).forEach(name => {
    result[name] = { open: false };
  });

  return result;
}

export function useDialog<K extends DialogType, S extends State>(
  name: K
): UseDialog<PropType<K, S>> {
  const { dialogs, setDialogs } = useContext(DialogsContext);

  const open = useCallback(
    (props: PropType<K, S>) => {
      setDialogs({
        ...dialogs,
        [name]: {
          open: true,
          props,
        },
      });
    },
    [dialogs, name, setDialogs]
  );

  const close = useCallback(() => {
    setDialogs({
      ...dialogs,
      [name]: {
        open: false,
      },
    });
  }, [dialogs, name, setDialogs]);

  return [dialogs[name].open, { open, close }];
}

const config = {
  confirm: ConfirmDialog,
  categoryAdd: AddCategoryDialog,
  activityAdd: AddActivityDialog,
  sessionAdd: AddSessionDialog,
  shiftAdd: AddShiftDialog,
  shiftUpdate: UpdateShiftDialog,
  sessionInvite: SessionInviteAttendantsDialog,
  sessionSendMessage: SessionSendMessageDialog,
  sessionEdit: SessionEditDialog,
  sessionRegistration: SessionRegistrationDialog,
  sessionReservation: SessionReservationDialog,
  pageAdd: AddPageDialog,
  pageEdit: PageEditDialog,
  invoiceSync: InvoiceSyncDialog,
  httpMessage: HttpRequestDialog,
  changeSession: ChangeSessionDialog,
  memberInvite: InviteMemberDialog,
  discountAdd: AddDiscountDialog,
  quantityDiscountAdd: AddQuantityDiscountDialog,
  quantityDiscountEdit: EditQuantityDiscountDialog,
  sessionAbsence: SessionAbsenceDialog,
  addInvoiceDiscount: AddInvoiceDiscountDialog,
  setInvoicesDiscount: SetInvoicesDiscountDialog,
  updateInvoiceItem: UpdateInvoiceItemDialog,
  paymentAdd: AddPaymentDialog,
  paymentEdit: EditPaymentDialog,
};

const defaultState = initState(config);

const DialogsContext = createContext<ContextProps>({
  dialogs: defaultState,
  setDialogs: () => {},
});

const Dialogs = ({ children }: Props) => {
  const [dialogs, setDialogs] = useState<State>(defaultState);

  return (
    <DialogsContext.Provider value={{ dialogs, setDialogs }}>
      {children}
      {Object.keys(config).map(name => {
        const Component = config[name as DialogType];
        const dialog = dialogs[name as DialogType];

        return dialog.open ? (
          <Component key={name} {...(dialog.props as any)} />
        ) : null;
      })}
    </DialogsContext.Provider>
  );
};

export default Dialogs;
