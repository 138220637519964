import TableCell from '@mui/material/TableCell';
import PaymentsTableRow from 'components/PaymentsTableRow';
import Table, { TableProps } from 'components/Table';
import TableRow from 'components/TableRow';

import { usePayments } from 'features/Payment';
import { usePagination } from 'components/Pagination';

type Props = TableProps;

const PaymentsTable = (props: Props) => {
  const [{ page, size }] = usePagination();
  const payments = usePayments({ page, size });

  return (
    <Table
      paginated
      loading={payments.isPending}
      count={payments.data?.meta?.pageInfo?.count}
      head={
        <TableRow>
          <TableCell component="th">Transakcijos ID</TableCell>
          <TableCell component="th">Mokėjimo paskirtis</TableCell>

          <TableCell component="th" align="center">
            Veikla
          </TableCell>

          <TableCell component="th" align="center">
            Grupė
          </TableCell>

          <TableCell component="th" align="center">
            Užsakovas
          </TableCell>

          <TableCell component="th" align="center">
            Suma
          </TableCell>

          <TableCell component="th" align="center">
            Statusas
          </TableCell>

          <TableCell component="th" align="center">
            Užsakymo data
          </TableCell>

          <TableCell component="th" align="center">
            Apmokėjimo data
          </TableCell>

          <TableCell component="th" />
        </TableRow>
      }
      {...props}>
      {(payments.data?.data ?? []).map(payment => (
        <PaymentsTableRow key={payment.id} payment={payment} />
      ))}
    </Table>
  );
};

export default PaymentsTable;
