import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Section from 'components/Section';
import { UserDetailsView } from 'schema';

type Props = {
  user: UserDetailsView;
};

const InvoiceUser = ({ user }: Props) => (
  <Section title="Mokėtojas">
    <List sx={{ width: '100%' }}>
      <ListItem disableGutters>
        <ListItemText primary="Vardas pavardė" secondary={user.fullName} />
      </ListItem>

      <ListItem disableGutters>
        <ListItemText primary="El. paštas" secondary={user.email} />
      </ListItem>

      <ListItem disableGutters>
        <ListItemText primary="Telefonas" secondary={user.phone} />
      </ListItem>
    </List>
  </Section>
);

export default InvoiceUser;

