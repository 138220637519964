import { number, object, string } from 'yup';

import FormEuroTextField from 'components/FormEuroTextField';
import FormTextField from 'components/FormTextField';
import Stack from 'components/Stack';
import { PaymentMethod } from 'schema';

export type Fields = {
  amount: string;
  comment: string;
};

type Props = {
  payment?: {
    method: PaymentMethod;
  };
};

export const schema = object({
  amount: number()
    .typeError('Prašome vesti skaičių')
    .required('Prašome įvesti mokėjimo sumą'),
  comment: string().required('Prašome įvesti komentarą'),
});

const PaymentFormFields = ({ payment }: Props) => (
  <Stack>
    <FormEuroTextField
      name="amount"
      label="Mokėjimo suma"
      disabled={payment?.method === 'neopay'}
    />

    <FormTextField
      fullWidth
      multiline
      maxRows={5}
      name="comment"
      label="Komentaras"
    />
  </Stack>
);

export default PaymentFormFields;
