import { ForwardedRef, forwardRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

type Props = BoxProps & {
  children: number;
};

const EuroPrice = (props: Props, ref: ForwardedRef<HTMLSpanElement>) => {
  const { children, sx = [], ...rest } = props;

  return (
    <Box
      ref={ref}
      component="span"
      sx={[{ whiteSpace: 'nowrap' }, ...(Array.isArray(sx) ? sx : [sx])]}
      {...rest}>
      {children.toFixed(2)} €
    </Box>
  );
};

export default forwardRef(EuroPrice);
